/*=======================================
    3.08 Testimonial CSS
=========================================*/
.testimonial-content {
    margin-top: -8px;
    margin-bottom: 35px;
    & p {
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 32px;
        color: $dark;
        width: 750px;
        margin: 0 auto;
        @media #{$tablet-device} {
            width: 500px;
        }
        @media #{$large-mobile} {
            width: 430px;
            font-size: 16px;
        }
        @media #{$extra-small-mobile} {
            width: 280px;
        }
    }
}
.testimonial-thumb {
    margin-bottom: -10px;
    & img {
        width: 100px;
        height: 100px;
        border: 1px solid $primary;
        border-radius: 100%;
    }

    & .thumb-title {
        font-size: 16px;
        font-weight: 500;
        margin-top: 35px;
        line-height: 1;
    }
    & .thumb-subtitle {
        font-size: 16px;
        font-weight: 400;
        margin-top: 5px;
        line-height: 1;
    }
}
/*----------------------------------------*/
/*  03. Component CSS
/*----------------------------------------*/

/*=======================================
    3.01 Button CSS
=========================================*/
.btn {
    font-size: 14px;
    padding: 15px 30px;
    border: 1px solid transparent;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    &:focus {
        box-shadow: none;
    }
    &:active{
        &:focus{
            box-shadow: none;
        }
    }
}
/* Button Color & Style (Solid) */
@each $name, $value in $theme-colors {
    .btn-#{$name} {
        border-color: $value;
        background-color: $value;

        @if $name==warning or $name==light {
            color: $dark;
        }

        @else if $name==gray-deep {
            color: $dark;
            border-radius: 50px;
            padding: 13px 30px;
        }

        @else if $name==primary {
            color: $white;
            background-color: $primary;
            border: 1px solid transparent;
        }

        @else if $name==whited {
            color: $dark;
            background-color: $white;
            border: 1px solid transparent;
        }

        @else {
            color: $white;
        }
    }
}
@each $name, $value in $theme-colors {
    .btn-hover-#{$name} {
        &:hover {
            border-color: $value;
            background-color: $value;
            @if $name == light {
                color: $white;
                background-color: $headings-color;
                border: 1px solid transparent;
            } 
            @else if $name == warning {
                color: $headings-color;
            }
            @else {
                color: $white;
            }
        }
    }
}
/* Button Color & Style (Outline) */
@each $name, $value in $theme-colors {
    .btn-outline-#{$name} {
        border-color: $value;
        background-color: $value;

        @if $name==warning or $name==light {
            color: $dark;
            border: 2px solid $light;
            background-color: transparent;
        }

        @else if $name==primary {
            color: $white;
            background-color: $primary;
            border: 1px solid transparent;
        }

        @else if $name==whited {
            color: $dark;
            background-color: $white;
            border: 1px solid transparent;
        }

        @else {
            color: $white;
        }
    }
}
@each $name, $value in $theme-colors {
    .btn-outline-hover-#{$name} {
        &:hover {
            border-color: $value;
            background-color: $value;
            @if $name == light {
                color: $white;
                background-color: $headings-color;
                border: 2px solid transparent;
            } 
            @else if $name == warning {
                color: $headings-color;
            }
            @else {
                color: $white;
            }
        }
    }
}
/*=======================================
    3.11 Sidebar CSS
=========================================*/
/*-- Shop Sidebar Area Start --*/
.sidebar_widget {
    & .widget_inner {
        & .widget-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 1;
            position: relative;
            text-transform: uppercase;
        }
        & .search-box {
            display: flex;
            flex-wrap: wrap;
            & .form-control {
                border: 1px solid $gray-100;
                padding: 10px;
                transition: .3s;
                display: block;
                border-radius: 0;
                height: 50px;
                font-size: 12px;
                width: calc(100% - 50px);
                &:focus {
                    box-shadow: none;
                }
            }
            & .search-icon {
                border-radius: 0;
                color: $dark;
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px;
                font-size: 20px;
                border: 1px solid $gray-100;
                border-left: 0px;
                background: transparent;
                &:focus {
                    box-shadow: none;
                }

                &:hover {
                    background: $primary;
                    border-color: $primary;
                    color: $white;
                }
            }
        }
        & .widget-list {
            & .sidebar-body{
                & .checkbox-container{
                    & li {
                        padding: 10px 0;
                        line-height: 1;

                        &:last-child {
                            padding-bottom: 0px;
                        }
                    }
                    & .custom-control-input {
                        margin-right: 5px;
                        cursor: pointer;
                    }
                    & .custom-control-label {
                        color: $body-color;
                        text-transform: capitalize;
                        cursor: pointer;
                        transition: $transition-base;
                        font-size: 14px;
                        font-weight: 500;

                        &:hover {
                            color: $primary;
                            transition: $transition-base;
                        }
                    }
                }
            }
        }
    }
    & .blog-list-wrapper {
        & .single-blog-list {
            display: flex;
            flex-wrap: wrap;

            & .blog-thumb {
                width: 70px;
                border: 1px solid $primary;
                height: 100%;
            }

            & .blog-list-content {
                width: calc(100% - 70px);
                padding-left: 10px;

                & .blog-name {
                    font-size: 14px;
                    font-weight: 600;
                    & a {
                        color: $body-color;
                        &:hover {
                            color: $primary;
                        }
                    }
                }

                & .blog-meta {
                    & p {
                        color: #8c8c8c;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        } 
    }
}
.sidebar-list {
    & > li {
        & > a {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 15px;
            color: $body-color;
            font-weight: 500;
            padding-bottom: 10px;

            &:hover {
                color: $primary;
            }
        }
        &:last-child{
            & a {
                padding-bottom: 0;
            }
        }
    }
}
.tags {
    & li {
        display: inline-block;
        & a {
            border: 1px solid #ccc;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 10px;
            color: $dark;
            font-size: 13px;
            padding: 5px 10px;
            &:hover{
                color: $white;
                background: $primary;
                border-color: $primary;
            }
        }
    }
}
.single-product-list {
    display: flex;
    flex-wrap: wrap;
    & .product {
        overflow: visible;
        width: 100px;
    }

    & .product-list-content {
        padding-left: 10px;
        width: calc(100% - 100px);
        & .product-name {
            font-size: 15px;
            line-height: 1.5;
            padding-bottom: 10px;
            margin-bottom: 0;
            font-weight: 500;

            &:hover {
                & a {
                    text-decoration: underline;
                    color: $primary;
                }
            }
        }

        & .price {
            font-size: 15px;
            line-height: 1;

            display: flex;
            align-items: center;
            justify-content: start;

            color: $dark;
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            & span {

                &.new {
                    color: $primary;
                }

                &.old {
                    font-size: 14px;
                    margin-left: 10px;
                    text-decoration: line-through;
                    color: $danger;
                    opacity: .5;
                }
            }
        }
    }
}
.sidebar-body-archive {

    & .title {
        font-size: 14px;
        font-weight: 700;
        color: $body-color;
    }

    & .archive-list {
        & li {

            line-height: 35px;
            color: #242424;
            font-size: 15px;
            transition: .3s;
            & span {
                margin-right: 5px;
            }
            & a {
                text-decoration: none;
                color: $body-color;

                &:hover {
                    color: $primary;
                    padding-left: 5px;
                }
            }
        }
    }
}
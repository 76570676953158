/*=======================================
    4.03 Contact CSS
=========================================*/
.contact-title {
    & .title {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }
}
.contact-content {
    & p {
        font-size: 14px;
        line-height: 2;
    }
    & .contact-block {
        margin: 30px 0;
        & ul {
            & li {
                margin-bottom: 15px;
                padding-bottom: 15px;
                font-size: 14px;
                border-bottom: 1px solid #ddd;
                display: flex;
                flex-direction: row;
                align-items: center;

                &:last-child {
                    border-bottom: 0px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                & i {
                    font-size: 18px;
                    font-weight: 500;
                    margin-right: 10px;
                }

                & a {
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    & .working-time {
        & .title {
            font-weight: 600;
        }
        & p {
            font-size: 14px;
        }
    }
}
.contact-form-wrapper{
    & .input-area{
        & .input-item, & .textarea-item{
            padding: 10px 15px;
            background: #f8f8f8;
            border: 1px solid transparent;
            transition: .3s;
            width: 100%;
            font-size: 14px;
            &:focus{
                border: 1px solid transparent;
                outline: 0px;
                box-shadow: none;
            }
        }
    }
    & .btn {
        border-radius: 50px;
    }
}
/* Contact Map */
.contact-map {
    height: 500px;
    width: 100%;
    display: block;
}